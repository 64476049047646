<template>
  <v-container>
    <back-toolbar
      v-if="!$store.state.isMiKY"
      title="login.loginEmail"
    ></back-toolbar>
    <back-toolbar v-else title="login.signIn"></back-toolbar>
    <v-form class="mt-16" lazy-validation>
      <v-row dense class="mx-3">
        <v-col
          v-if="!$store.state.isMiKY || !$store.state.profile.data.email"
          cols="12"
          :sm="!showForgotPw ? '6' : '12'"
        >
          <v-text-field
            :class="$store.state.settings.dark ? 'textfield-dark' : ''"
            v-model="email"
            :error-messages="emailErrors"
            solo
            :placeholder="$t('profile.email')"
            maxlength="100"
            required
            @input="email ? (email = email.trim()) : '', $v.email.$touch()"
            @blur="getEmailErrors()"
          ></v-text-field>
        </v-col>
        <v-col v-else cols="12" :sm="!showForgotPw ? '6' : '12'">
          <v-text-field
            disabled
            :class="$store.state.settings.dark ? 'textfield-dark' : ''"
            v-model="$store.state.profile.data.email"
            :error-messages="emailErrors"
            solo
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            :class="$store.state.settings.dark ? 'textfield-dark' : ''"
            v-if="!showForgotPw"
            v-model="pw"
            :error-messages="pwErrors"
            solo
            :placeholder="$t('profile.password')"
            maxlength="50"
            required
            @input="$v.pw.$touch()"
            @blur="getPwErrors()"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="show = !show"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row dense class="mx-3">
      <v-col cols="12">
        <v-spacer></v-spacer>
        <div class="Text-Color-Text-3---14-Right-Align mb-2">
          <router-link
            v-if="!$store.state.isMiKY"
            :to="{ name: 'resetPassword' }"
          >
            {{ $t("login.forgotPassword") }}
          </router-link>
          <router-link v-else :to="{ name: 'resetPassword' }">
            {{ $t("login.forgotOrSetPassword") }}
          </router-link>
        </div>
      </v-col>
    </v-row>
    <v-alert
      class="transition-swing"
      transition="scale-transition"
      type="error"
      v-model="error"
      >{{ errorMessage }}</v-alert
    >
    <v-row dense class="mx-3">
      <v-col>
        <v-btn
          :loading="signinStatus === 'fetching'"
          v-if="!showForgotPw"
          @click="login"
          height="48"
          width="100%"
          depressed
          color="primary"
          class="gradient d-block mx-auto Heading-White-H4-18-Center"
        >
          {{ $t("login.signIn") }}
        </v-btn>
        <v-btn
          v-if="!$store.state.isMiKY"
          text
          color="primary"
          class="Heading-Color-H4-18-Center mx-auto d-block mt-4"
          @click="$router.safePush({ name: 'register' })"
          >{{ $t("login.registration") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import BackToolbar from "@/components/app/common/BackToolbar";
export default {
  validations: {
    email: {
      required,
      email: (val) => {
        if (!val) return val;
        let parts = val.split("@");
        if (parts.length !== 2) return val;
        parts[1] = parts[1].toLowerCase();
        return parts.join("@");
      },
    },
    pw: { required },
  },
  components: {
    BackToolbar,
  },
  data() {
    return {
      show: false,
      loginData: false,
      email: "",
      pw: "",
      showForgotPw: false,
      showErrorReset: false,
      emailErrors: [],
      pwErrors: [],
    };
  },
  methods: {
    async login() {
      this.$v.$touch();
      this.getEmailErrors();
      this.getPwErrors();

      this.$store.commit("session/CLEAR_AUTH_ERROR");
      if (this.$store.state.isMiKY && this.$store.state.profile.data.email) {
        this.email = this.$store.state.profile.data.email;
      }

      if (this.$v.$invalid) {
        return;
      }
      const user = {
        email: this.email,
        password: this.pw,
      };
      await this.$store.dispatch("session/signIn", {
        user: user,
        reload: !this.$store.state.isMiKY,
      });
    },

    getEmailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        this.emailErrors = errors;
        return false;
      }

      !this.$v.email.email && errors.push(this.$t("profile.validMail"));

      !this.$v.email.required && errors.push(this.$t("profile.requireMail"));

      this.emailErrors = errors;
      return errors.length > 0;
    },

    getPwErrors() {
      const errors = [];

      if (!this.$v.pw.$dirty) {
        this.pwErrors = errors;
        return false;
      }

      !this.$v.pw.required && errors.push(this.$t("profile.enterYourPassword"));

      this.pwErrors = errors;
      return errors.length > 0;
    },
  },
  computed: {
    ...mapState({
      signinStatus: (state) => state.session.signinStatus,
    }),
    error: {
      get() {
        return typeof this.$store.state.session.error === "string";
      },
    },
    errorMessage: {
      get() {
        return this.$store.state.session.error;
      },
    },
  },
  watch: {
    "$store.state.session.resetStatus"(val) {
      this.showForgotPw = false;
      this.showErrorReset = false;

      if (val === "success") {
        this.showForgotPw = true;
      } else if (val === "failure") {
        this.showErrorReset = true;
      }
    },
  },
};
</script>
